import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { UserGroup } from '../types';
import DashboardPanel from './DashboardPanel';
import { useAuth0 } from '@auth0/auth0-react';


interface DashboardIconProps {
    handleSignOut: () => void;
    handleOpenHistory: () => void;
    handleGetGroupUsers: () => void;
    onNavigateToImageUpload: () => void;
}

const DashboardIcon: React.FC<DashboardIconProps> = ({ handleSignOut, handleOpenHistory, handleGetGroupUsers, onNavigateToImageUpload }) => {
    const [iconState, setIconState] = useState<'default' | 'hover' | 'active'>('default');
    const [isDashboardOpen, setIsDashboardOpen] = useState(false);
    const { logout } = useAuth0(); // Use Auth0's logout method

    // ダッシュボードパネルの表示/非表示をトグル
    const toggleDashboardPanel = () => {
        setIsDashboardOpen(prev => !prev);
    };

    const handleReviewHistory = () => {
        // 審査履歴の取得＆表示ロジック
        handleOpenHistory()
        setIsDashboardOpen(false); // パネルを閉じる
    };

    const handleGroupUsers = () => {
        // グループユーザーリストの取得＆表示ロジック
        handleGetGroupUsers()
        setIsDashboardOpen(false); // パネルを閉じる
    };

    const handleLogout = () => {
        // ログアウト処理
        setIsDashboardOpen(false); // パネルを閉じる
        // Log out using Auth0
        logout({
            logoutParams: {
                returnTo: window.location.origin, // Redirects the user to the home page after logout
            },
        });
        window.location.reload(); // ログアウト後にページをリロード
    };

    const getFillColor = () => {
        switch (iconState) {
            case 'hover':
                return '#7762E3'; // マウスホバー時
            case 'active':
                return '#5135DC'; // クリック時
            default:
                return '#232323'; // デフォルト
        }
    };

    // パネル外をクリックした場合にパネルを閉じる処理
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (!event.target) return;
            const target = event.target as HTMLElement;
            if (!target.closest('.dashboard-panel') && !target.closest('.dashboard-icon')) {
                setIsDashboardOpen(false);
            }
        };

        if (isDashboardOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isDashboardOpen]);

    return (
        <Box sx={{ position: 'relative' }}> {/* ここでrelativeを指定 */}
            <svg
                className="dashboard-icon"  // classNameを追加して外部クリックの判定に利用
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                onMouseEnter={() => setIconState('hover')}
                onMouseLeave={() => setIconState('default')}
                onMouseDown={() => setIconState('active')}
                onMouseUp={() => setIconState('hover')}
                onClick={toggleDashboardPanel}
                style={{ cursor: 'pointer' }}
            >
                <g clipPath="url(#clip0)">
                    <path d="M8.66667 23.334H0V28.0006C0 29.0615 0.421427 30.0789 1.17157 30.8291C1.92172 31.5792 2.93913 32.0006 4 32.0006H8.66667V23.334Z" fill={getFillColor()} />
                    <path d="M31.9997 23.334H23.333V32.0006H27.9997C29.0605 32.0006 30.0779 31.5792 30.8281 30.8291C31.5782 30.0789 31.9997 29.0615 31.9997 28.0006V23.334Z" fill={getFillColor()} />
                    <path d="M8.66667 11.666H0V20.3327H8.66667V11.666Z" fill={getFillColor()} />
                    <path d="M31.9997 11.666H23.333V20.3327H31.9997V11.666Z" fill={getFillColor()} />
                    <path d="M8.66667 0H4C2.93913 0 1.92172 0.421427 1.17157 1.17157C0.421427 1.92172 0 2.93913 0 4L0 8.66667H8.66667V0Z" fill={getFillColor()} />
                    <path d="M20.3336 23.334H11.667V32.0006H20.3336V23.334Z" fill={getFillColor()} />
                    <path d="M20.3336 11.666H11.667V20.3327H20.3336V11.666Z" fill={getFillColor()} />
                    <path d="M20.3336 0H11.667V8.66667H20.3336V0Z" fill={getFillColor()} />
                    <path d="M27.9997 0H23.333V8.66667H31.9997V4C31.9997 2.93913 31.5782 1.92172 30.8281 1.17157C30.0779 0.421427 29.0605 0 27.9997 0Z" fill={getFillColor()} />
                </g>
                <defs>
                    <clipPath id="clip0">
                        <rect width="32" height="32" fill="white" />
                    </clipPath>
                </defs>
            </svg>

            {isDashboardOpen && (
                <Box className="dashboard-panel"> {/* classNameを追加 */}
                    <DashboardPanel
                        onReviewHistory={handleReviewHistory}
                        onGroupUsers={handleGroupUsers}
                        onLogout={handleLogout}
                        onNavigateToImageUpload={onNavigateToImageUpload}
                    />
                </Box>
            )}
        </Box>
    );
};

export default DashboardIcon;
