import React, { useState } from 'react';
import { Box, Typography, CircularProgress, Button } from '@mui/material';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
interface ImageUploadProps {
  onImageUpload: (file: File, caption: string) => void;
}

const ImageUpload: React.FC<ImageUploadProps> = ({ onImageUpload }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [image, setImage] = useState<File | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [caption, setCaption] = useState<string>('');
  const [loading, setLoading] = useState(false);

  const handleFileChange = (file: File | null) => {
    if (file) {
      setImage(file);
    }
  };

  const handleUpload = async () => {
    if (!image) return;

    setLoading(true);
    try {
      const accessToken = await getAccessTokenSilently();
      const formData = new FormData();
      formData.append('file', image);

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_API}/image`,
        formData,
        {
          headers: {
            "Authorization": `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      const captionText = response.data.image_caption.result;
      setCaption(captionText);
      onImageUpload(image, captionText);
    } catch (error) {
      console.error('画像のアップロードに失敗しました:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);

    const file = e.dataTransfer.files[0];
    if (file) {
      setImage(file);
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        border: '1px dashed #5F46DE',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: loading ? 'wait' : 'pointer',
        transition: 'background-color 0.3s ease',
        backgroundColor: isDragging ? '#F5F5F5' : 'transparent',
        position: 'relative',
      }}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      onClick={() => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.onchange = (e) => {
          const file = (e.target as HTMLInputElement).files?.[0];
          if (file) {
            handleFileChange(file);
          }
        };
        input.click();
      }}
    >
      {loading && (
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
          <CircularProgress />
        </Box>
      )}
      {image ? (
        <Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 2,
            }}
          >
            <Typography variant="body1" color="text.secondary">
              選択された画像： {image.name}
            </Typography>
            <img src={URL.createObjectURL(image)} alt={image.name} width="50%" height="50%"/>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                e.stopPropagation();
                handleUpload();
              }}
              disabled={loading}
              sx={{
                marginBottom: '10px',
                backgroundColor: '#5F46DE',
                '&:hover': {
                  backgroundColor: '#5F46DE',
                },
              }}
            >
              アップロード
            </Button>
          </Box>
          {caption && (
            <Typography variant="body1" color="text.primary">
              {caption}
            </Typography>
          )}
        </Box>
      ) : (
        <Typography variant="body1" color="text.secondary">
          画像を選択してください
        </Typography>
      )}
    </Box>
  );
};

export default ImageUpload;
