import React from 'react';
import { Container, Typography, Box, Button } from '@mui/material';
import ImageUpload from '../components/ImageUpload';

interface ImageUploadPageProps {
  onBackToMain: () => void;
}

const ImageUploadPage: React.FC<ImageUploadPageProps> = ({ onBackToMain }) => {
  const handleImageUpload = (file: File, caption: string) => {
    console.log('Image uploaded:', file);
    console.log('Caption:', caption);
  };

  return (
    <Box sx={{ minHeight: '100vh', backgroundColor: '#F3F1FC', pt: 8 }}>
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          画像アップロード
        </Typography>
        <Box>
          <Typography
            gutterBottom
            sx={{
              fontSize: '18px',
              alignItems: 'left',
              justifyContent: 'left',
              display: 'flex',
              flexDirection: 'row',
              paddingLeft: '10px',
            }}>
              使用モデル: gpt-4o
            </Typography>
        </Box>
        <Box sx={{ mt: 4 }}>
          <ImageUpload onImageUpload={handleImageUpload} />
        </Box>
        <Button
          onClick={onBackToMain}
          sx={{
            marginTop: '20px',
          }}
        >
          計画書審査画面に戻る
        </Button>
      </Container>
    </Box>
  );
};

export default ImageUploadPage;
